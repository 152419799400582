import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-solarized_dark"; 
import styles from './editor.module.css'
export const Editor = ({program,handleSave,role,teacherCopy,studentActivityId}) => {
   
    const handleChange = (val) =>{
        AceEditor.value = val;
        // console.log(AceEditor.value);
        // endAndStartTimer();
    }

    var timer;
    const endAndStartTimer = () =>{
        clearTimeout(timer)
        timer = setTimeout(()=>{
           // alert("saving changes")
            console.log("saving");
            handleSave();
            console.log("saved");
        },5000)
    }

    return<AceEditor
    height="100%"
    width="inherit"
    placeholder="Enter code here!"
    mode="python"
    theme="solarized_dark"
    onChange={handleChange}
    value={program}
    // readOnly={ (role === "teacher" && teacherCopy == true )?true:false}
    readOnly={ (role === "teacher" && teacherCopy !=true && studentActivityId == null )?true:false}
    fontSize={20}
    showPrintMargin={true}
    showGutter={true}
    highlightActiveLine={true}
    setOptions={{
    // enableBasicAutocomplete: true,
    // enableLiveAutocompletion: false,
    // enableSnippets: false,
    showLineNumbers: true,
   // tabSize: 2,
    }}/>
}