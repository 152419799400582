import { useState } from "react";
import AceEditor from "react-ace";

export const Turtle = () => {
    const prog = useState("")
    const handleChange = (e) => {
        console.log(e);
    }
    console.log( AceEditor.value);
    return <div style={{ height: "100%", width: "100%" ,border:"1px solid black",overflowX:"scroll",overflowY:"scroll"}} id="forSize">
        {/* <textarea id="yourcode" style={{display:"hide"}} cols="70" rows="20" onChange={(e)=>{
        handleChange(e)
      }}></textarea><br/> */}
       {/*<button onClick={window.runit}>Run</button>*/}

        <pre id="output"></pre>
        <div id="mycanvas"></div>
    </div>
}