import { useEffect, useRef } from 'react'
import styles from './editor.module.css'
import AceEditor from "react-ace";
export default function Pyodide({ handleIsLoading, pythonCode, output, handleOutput, onRun, OnRunForModalOption }) {
    // const indexURL = 'https://cdn.jsdelivr.net/pyodide/v0.17.0/full/'
    // const [pyodide, setpy] = useState()
    const pyodide = useRef(null)
    // const [isPyodideLoading, setIsPyodideLoading] = useState(true)
    //const [pyodideOutput, setPyodideOutput] = useState(null)

    useEffect(() => {
        (async function () {
            pyodide.current = await window.loadPyodide({
                indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.20.0/full/'
            })
        })().then(() => {
            console.log("done");
            handleIsLoading()
        }).catch((err) => {
            console.log(err);
        })
        onRun.current = onClickRun;
        OnRunForModalOption.current = onClickRunForModal;

        return () => {
            onRun.current = null;
            OnRunForModalOption.current = null;
        }
    }, [pyodide])



    // useEffect(() => {
    //     if (!isPyodideLoading) {
    //       ;(async function () {
    //         setPyodideOutput(await pyodide.current.runPython(pythonCode))
    //       })()
    //     }
    //   }, [isPyodideLoading, pyodide, pythonCode])

    const evaluatePython = async (pythonCode) => {
        try {
            await pyodide.current.runPython(`
                import sys
                import io
                from js import input_fixed
                sys.stdout = io.StringIO()
                input = input_fixed
                __builtins__.input = input_fixed
            `);
            await pyodide.current.runPython(pythonCode)
            return await pyodide.current.runPython("sys.stdout.getvalue()")
        } catch (error) {
            console.log(error);
            return (error)
        }
    }

    async function onClickRun() {

        let val = AceEditor.value;
        handleOutput(await evaluatePython(val))

    }

    async function onClickRunForModal(val) {
        handleOutput(await evaluatePython(val))
    }

    return (
        <>
            <textarea
                id="outputConsole"
                className={styles.pythonoutput}
                readOnly
                value={output}
                placeholder="> output goes here..."
            />
        </>
    )
}