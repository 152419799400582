import styles from './buttonStyles.module.css'
export const TeacherViewModal = ({ handleOpenModal, setModalIsOpen}) => {
    return <>
        <h2>Sorry, you don't have Access</h2>
        <div style={{ display: "flex", margin: "15px 0px", justifyContent: "end" }}>
            <button  className={styles.sucess} style={{ marginRight: "10px" }} onClick={() => { setModalIsOpen(false); handleOpenModal(false) }}>OK</button>
            {/* <button  className={styles.sucess} onClick={() => {
                handleOpenModal(false)
                setModalIsOpen(false)
            }}>Delet</button> */}
        </div>
    </>
}