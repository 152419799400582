import { useState } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { NewFolderModal } from "./modals/newfolderModal";
import { NewFileModal } from "./modals/newFileModal";
import { RenameFileModal } from "./modals/renameFileModal";
import { RenameFolderModal } from "./modals/renameFolderModal";
import { DeleteFileModal } from "./modals/deleteFileModal";
import { DeleteFolderModal } from "./modals/deleteFolderModal";
import { UploadModal } from "./modals/uploadModal";
import { TeacherViewModal } from "./modals/teacherViewModal";

export const Modal = ({
  handleTabfiles,
  handleTabsOnRemove,
  projectID,
  wholeProjectData,
  type,
  pid,
  data,
  isOpen,
  handleOpenModal,
  handleUpdate,
  update,
  handleWholeProjectData,
  role,
  studentCopy,
  teacherCopy,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);
  const [dataInput, setDataInput] = useState("");

  const handleOnchange = (e) => {
    setDataInput(e.target.value);
  };

  async function postFileOrFolder() {
    console.log("ISNSID");
    if (type === "file") {
      let len = dataInput.length;
      let word = dataInput.substring(len - 3);
      if (".py" !== word) {
        alert("Error plz provide a valid extension");
        return;
      }
    }

    let newdata = {
      file_id: Math.floor(Math.random() * 10000 + 1000),
      project_id: projectID,
      type: type,
      parent: pid === 0 ? null : pid,
      parents: null,
      name: dataInput,
      path: "dkfkmd",
      code: null,
    };

    let updatedData = [...wholeProjectData, newdata];
    // updatedData = encodeURIComponent(JSON.stringify(updatedData));
    //await axios.post(`https://testserver.begalileo.com/access_codings/update_project?user_id=63438&student_activity_id=44&coding_activity_id=1&project_data=${updatedData}`)

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let user_id = params.get("user_id");
    let student_activity_id = params.get("student_activity_id");
    let coding_activity_id = params.get("coding_activity_id");
    let coding_learning_outcome_id = params.get("coding_learning_outcome_id");
    let env = params.get("env");

    if (
      role == "teacher" &&
      studentCopy == "yes" &&
      student_activity_id == null
    ) {
      return;
    }

    let url = "";
    if (env == "staging") {
      url = " https://staging.begalileo.com/access_codings/update_project";
    } else if (env == "test") {
      url = "https://testserver.begalileo.com/access_codings/update_project";
    } else {
      url = "https://www.begalileo.com/access_codings/update_project";
    }

    console.log("env in updating", env, url);
    var formdata = new FormData();

    let parameters = new URLSearchParams(search);
    let homework_id = parameters.get("homework_id");
    formdata.append("project_data", JSON.stringify(updatedData));
    formdata.append("user_id", user_id);
    if (student_activity_id != null) {
      formdata.append("student_activity_id", student_activity_id);
    }
    formdata.append("coding_activity_id", coding_activity_id);
    formdata.append("coding_learning_outcome_id", coding_learning_outcome_id);
    formdata.append("teacher_copy", teacherCopy);
    formdata.append("homework_id", homework_id);
    formdata.append("student_copy", studentCopy);

    await axios({
      method: "post",
      url: url,
      data: formdata,
      headers: { "Content-Type": "application/json" },
    }).then(
      (response) => {
        console.log({ response });
      },
      (error) => {
        console.log(error);
      }
    );

    handleUpdate(!update);
  }

  async function deleteFile() {
    console.log(data);
    if (data.name === "main.py") {
      alert("you are not suppossed to delete this file");
      return;
    }
    handleTabsOnRemove(data.file_id);
    let updatedData = wholeProjectData.filter((e) =>
      e.file_id === data.file_id ? 0 : 1
    );
    //updatedData = encodeURIComponent(JSON.stringify(updatedData));
    // await axios.post(`https://testserver.begalileo.com/access_codings/update_project?user_id=63438&student_activity_id=44&coding_activity_id=1&project_data=${updatedData}`)
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let user_id = params.get("user_id");
    let homework_id = params.get("homework_id");
    let student_activity_id = params.get("student_activity_id");
    let coding_activity_id = params.get("coding_activity_id");
    let coding_learning_outcome_id = params.get("coding_learning_outcome_id");
    let env = params.get("env");
    let url = "";
    if (env == "staging") {
      url = " https://staging.begalileo.com/access_codings/update_project";
    } else if (env == "test") {
      url = "https://testserver.begalileo.com/access_codings/update_project";
    } else {
      url = "https://www.begalileo.com/access_codings/update_project";
    }

    if (
      role == "teacher" &&
      studentCopy == "yes" &&
      student_activity_id == null
    ) {
      return;
    }

    var formdata = new FormData();

    formdata.append("project_data", JSON.stringify(updatedData));
    formdata.append("user_id", user_id);
    if (student_activity_id != null) {
      formdata.append("student_activity_id", student_activity_id);
    }
    formdata.append("homework_id", homework_id);
    formdata.append("coding_activity_id", coding_activity_id);
    formdata.append("coding_learning_outcome_id", coding_learning_outcome_id);
    formdata.append("teacher_copy", teacherCopy);
    formdata.append("student_copy", studentCopy);

    await axios({
      method: "post",
      url: url,
      // url: "https://www.begalileo.com/access_codings/update_project",
      data: formdata,
      headers: { "Content-Type": "application/json" },
    }).then(
      (response) => {
        console.log({ response });
      },
      (error) => {
        console.log(error);
      }
    );

    handleUpdate(!update);
  }

  async function deleteFolder() {
    console.log("deleteFolder", data);
    //have to delete all childrens in folders
    let filesInFolder = wholeProjectData.filter((e) =>
      Number(e.parent) === Number(data.file_id) ? 1 : 0
    );
    handleTabfiles(filesInFolder);

    let updatedData = wholeProjectData.filter((e) =>
      e.file_id === data.file_id || e.parent === data.file_id ? 0 : 1
    );
    // updatedData = encodeURIComponent(JSON.stringify(updatedData))
    // await axios.post(`https://testserver.begalileo.com/access_codings/update_project?user_id=63438&student_activity_id=44&coding_activity_id=1&project_data=${updatedData}`)
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let user_id = params.get("user_id");
    let student_activity_id = params.get("student_activity_id");
    let coding_activity_id = params.get("coding_activity_id");
    let homework_id = params.get("homework_id");
    let coding_learning_outcome_id = params.get("coding_learning_outcome_id");
    let env = params.get("env");
    let url = "";
    if (env == "staging") {
      url = " https://staging.begalileo.com/access_codings/update_project";
    } else if (env == "test") {
      url = "https://testserver.begalileo.com/access_codings/update_project";
    } else {
      url = "https://www.begalileo.com/access_codings/update_project";
    }

    if (
      role == "teacher" &&
      studentCopy == "yes" &&
      student_activity_id == null
    ) {
      return;
    }

    var formdata = new FormData();

    formdata.append("project_data", JSON.stringify(updatedData));
    formdata.append("user_id", user_id);
    if (student_activity_id != null) {
      formdata.append("student_activity_id", student_activity_id);
    }
    formdata.append("homework_id", homework_id);
    formdata.append("coding_activity_id", coding_activity_id);
    formdata.append("coding_learning_outcome_id", coding_learning_outcome_id);
    formdata.append("teacher_copy", teacherCopy);
    formdata.append("student_copy", studentCopy);

    await axios({
      method: "post",
      url: url,
      // url: "https://www.begalileo.com/access_codings/update_project",
      data: formdata,
      headers: { "Content-Type": "application/json" },
    }).then(
      (response) => {
        console.log({ response });
      },
      (error) => {
        console.log(error);
      }
    );

    handleUpdate(!update);
  }

  async function renameFile(name) {
    console.log("updated file name", name);
    if (data.name === "main.py") {
      alert("you are not suppossed to rename this file");
      return;
    }
    let len = name.length;
    let word = name.substring(len - 3);
    if (".py" !== word) {
      alert("Error plz provide a valid extension");
      return;
    }

    let updatedData = wholeProjectData.map((e) => {
      if (e.file_id === data.file_id) {
        e.name = name;
      }
      return e;
    });
    // updatedData = encodeURIComponent(JSON.stringify(updatedData))
    // await axios.post(`https://testserver.begalileo.com/access_codings/update_project?user_id=63438&student_activity_id=44&coding_activity_id=1&project_data=${updatedData}`)
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let user_id = params.get("user_id");
    let student_activity_id = params.get("student_activity_id");
    let coding_activity_id = params.get("coding_activity_id");
    let coding_learning_outcome_id = params.get("coding_learning_outcome_id");
    let env = params.get("env");
    let homework_id = params.get("homework_id");

    let url = "";
    if (env == "staging") {
      url = " https://staging.begalileo.com/access_codings/update_project";
    } else if (env == "test") {
      url = "https://testserver.begalileo.com/access_codings/update_project";
    } else {
      url = "https://www.begalileo.com/access_codings/update_project";
    }

    if (
      role == "teacher" &&
      studentCopy == "yes" &&
      student_activity_id == null
    ) {
      return;
    }

    var formdata = new FormData();

    formdata.append("project_data", JSON.stringify(updatedData));
    formdata.append("user_id", user_id);
    if (student_activity_id != null) {
      formdata.append("student_activity_id", student_activity_id);
    }
    formdata.append("homework_id", homework_id);
    formdata.append("coding_activity_id", coding_activity_id);
    formdata.append("coding_learning_outcome_id", coding_learning_outcome_id);
    formdata.append("teacher_copy", teacherCopy);
    formdata.append("student_copy", studentCopy);

    await axios({
      method: "post",
      url: url,
      // url: "https://www.begalileo.com/access_codings/update_project",
      data: formdata,
      headers: { "Content-Type": "application/json" },
    }).then(
      (response) => {
        console.log({ response });
      },
      (error) => {
        console.log(error);
      }
    );

    handleUpdate(!update);
  }

  async function renameFolder(name) {
    console.log("updated folder name", name);

    let updatedData = wholeProjectData.map((e) => {
      if (e.file_id === data.file_id) {
        e.name = name;
      }
      return e;
    });
    // updatedData = encodeURIComponent(JSON.stringify(updatedData))
    // await axios.post(`https://testserver.begalileo.com/access_codings/update_project?user_id=63438&student_activity_id=44&coding_activity_id=1&project_data=${updatedData}`)
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let user_id = params.get("user_id");
    let student_activity_id = params.get("student_activity_id");
    let coding_activity_id = params.get("coding_activity_id");
    let coding_learning_outcome_id = params.get("coding_learning_outcome_id");
    let homework_id = params.get("homework_id");
    let env = params.get("env");
    let url = "";
    if (env == "staging") {
      url = " https://staging.begalileo.com/access_codings/update_project";
    } else if (env == "test") {
      url = "https://testserver.begalileo.com/access_codings/update_project";
    } else {
      url = "https://www.begalileo.com/access_codings/update_project";
    }

    if (
      role == "teacher" &&
      studentCopy == "yes" &&
      student_activity_id == null
    ) {
      return;
    }

    var formdata = new FormData();

    formdata.append("project_data", JSON.stringify(updatedData));
    formdata.append("user_id", user_id);
    if (student_activity_id != null) {
      formdata.append("student_activity_id", student_activity_id);
    }
    formdata.append("homework_id", homework_id);
    formdata.append("coding_activity_id", coding_activity_id);
    formdata.append("coding_learning_outcome_id", coding_learning_outcome_id);
    formdata.append("teacher_copy", teacherCopy);
    formdata.append("student_copy", studentCopy);

    await axios({
      method: "post",
      url: url,
      // url: "https://www.begalileo.com/access_codings/update_project",
      data: formdata,
      headers: { "Content-Type": "application/json" },
    }).then(
      (response) => {
        console.log({ response });
      },
      (error) => {
        console.log(error);
      }
    );

    handleUpdate(!update);
  }

  console.log(role);

  return (
    <>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          handleOpenModal(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            zIndex: 101,
          },
          content: {
            height: "fit-content",
            boxSizing: "border-box",
            width: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "hidden",
            padding: "0px 10px 0px 10px",
          },
        }}
      >
        {type === "folder" &&
        (role == "teacher" ||
          role == "content" ||
          role == "academics" ||
          role == "superadmin" ||
          role == "student") ? (
          <NewFolderModal
            handleOnchange={handleOnchange}
            setModalIsOpen={setModalIsOpen}
            postFileOrFolder={postFileOrFolder}
            handleOpenModal={handleOpenModal}
          />
        ) : type === "file" &&
          (role == "teacher" ||
            role == "content" ||
            role == "academics" ||
            role == "superadmin" ||
            role == "student") ? (
          <NewFileModal
            handleOnchange={handleOnchange}
            setModalIsOpen={setModalIsOpen}
            postFileOrFolder={postFileOrFolder}
            handleOpenModal={handleOpenModal}
          />
        ) : type === "renameFile" &&
          (role == "teacher" ||
            role == "content" ||
            role == "academics" ||
            role == "superadmin" ||
            role == "student") ? (
          <RenameFileModal
            setModalIsOpen={setModalIsOpen}
            handleOpenModal={handleOpenModal}
            handleOnchange={handleOnchange}
            renameFile={renameFile}
            data={data}
          />
        ) : type === "renameFolder" &&
          (role == "teacher" ||
            role == "content" ||
            role == "academics" ||
            role == "superadmin" ||
            role == "student") ? (
          <RenameFolderModal
            setModalIsOpen={setModalIsOpen}
            handleOpenModal={handleOpenModal}
            handleOnchange={handleOnchange}
            renameFolder={renameFolder}
            data={data}
          />
        ) : type === "deleteFile" &&
          (role == "teacher" ||
            role == "content" ||
            role == "academics" ||
            role == "superadmin" ||
            role == "student") ? (
          <DeleteFileModal
            setModalIsOpen={setModalIsOpen}
            handleOpenModal={handleOpenModal}
            deleteFile={deleteFile}
            data={data}
          />
        ) : type === "deleteFolder" &&
          (role == "teacher" ||
            role == "content" ||
            role == "academics" ||
            role == "superadmin" ||
            role == "student") ? (
          <DeleteFolderModal
            setModalIsOpen={setModalIsOpen}
            handleOpenModal={handleOpenModal}
            deleteFolder={deleteFolder}
            data={data}
          />
        ) : type === "uploadFile" &&
          (role == "teacher" ||
            role == "content" ||
            role == "academics" ||
            role == "superadmin" ||
            role == "student") ? (
          <UploadModal
            setModalIsOpen={setModalIsOpen}
            handleOpenModal={handleOpenModal}
            handleUpdate={handleUpdate}
            pid={pid}
            update={update}
            projectID={projectID}
            wholeProjectData={wholeProjectData}
          />
        ) : (
          <TeacherViewModal
            setModalIsOpen={setModalIsOpen}
            handleOpenModal={handleOpenModal}
          />
        )}
      </ReactModal>
    </>
  );
};
